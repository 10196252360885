<template>
  <div>
    <h1>UPLOAD FILES</h1>
    <input type="file" ref="elInputFile" multiple />
    <app-button-submit @click="upload">Subir</app-button-submit>
  </div>
</template>
<script>
import { GeneralService } from "../general-module/GeneralService.js";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    upload() {
      GeneralService.uploadImages(this.$refs.elInputFile.files).then(() => {
        window.alert("subido");
      });
    }
  }
};
</script>

<style scoped></style>
